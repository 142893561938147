import './home.scss';

import React, { useEffect } from 'react';

import { getArbeitnehmerRegisterUrl, REDIRECT_URL } from 'app/shared/util/url-utils';
import Testimonial from 'app/shared/slider/testimonial';
import { Link, Route } from 'react-router-dom';

export const Arbeitnehmer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const redirectURL = localStorage.getItem(REDIRECT_URL);
    if (redirectURL) {
      localStorage.removeItem(REDIRECT_URL);
      location.href = `${location.origin}${redirectURL}`;
    }
  });

  return (
    <body className="body-white">
      <div
        data-w-id="c8d14f16-9cae-1193-1e96-41afa0a5ca8a"
        data-animation="default"
        data-collapse="medium"
        data-duration="400"
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        className="navbar-standard w-nav"
      ></div>

      <div className="flex-content-vert gap-4 padding-top-40">
        <div className="hero-top-wrapper">
          <img className="img-hero" src="../../../content/images/arbeitnehmer-hero-maler.webp" />

          <div className="div-block-33  wrapper-735px">
            <h1 className="heading-12">
              <strong className="bold-text-8 black">Traumjob als Maler & Lackierer gesucht?</strong>
            </h1>
            <div className="padding-15"></div>
            <p className="paragraph-15">
              Erstelle in weniger als 2 Minuten Dein kostenloses Profil und erhalte Angebote von Unternehmen inkl. Gehaltsangabe.
            </p>
            <div className="padding-15"></div>
            <div className="div-block-34">
              <Link to={getArbeitnehmerRegisterUrl()} className="call-to-action-max w-button">
                JETZT kostenlos Angebote erhalten
              </Link>
            </div>
          </div>
        </div>
        <div className="trust-item-container">
          <div className="flex-content-cust gap-4">
            <div className="trust-item">
              <span>
                <h5>Mehr Gehalt</h5>
              </span>
              <span>Erhalte Jobangebote direkt – inkl. Gehaltsangabe.</span>
            </div>
            <div className="trust-item">
              <span>
                <h5>Kein Aufwand</h5>
              </span>
              <span>Finde Deinen Job ohne Lebenslauf oder Anschreiben.</span>
            </div>
            <div className="trust-item">
              <span>
                <h5>Top Angebote</h5>
              </span>
              <span>Erhalte nur Jobangebote, die zu Dir passen.</span>
            </div>
          </div>
        </div>
        {/*<div className="div-block-34 flex-content-cust">
        <a href="#vorteile" className="call-to-action-max w-button">
          Warum Flendly
        </a>
      </div>*/}
      </div>

      <div className="divider" />

      <div className="section-plain-white">
        <div className="padding-section-30"></div>
        <h1 id="vorteile" className="heading-1">
          <strong>Deine Vorteile bei Flendy</strong>
          <br />
        </h1>
        <section className="hero-heading-left">
          <div className="container-22">
            <div className="hero-wrapper">
              <div className="hero-split">
                <h1 className="heading-11">
                  <p>Keine Bewerbungen &amp; kein Anschreiben</p>
                </h1>
                <p className="margin-bottom-24px">
                  Unternehmen finden Dich basierend auf Deinem Profil und machen Dir Angebote inkl. Gehaltsangabe.
                </p>
                <Link to={getArbeitnehmerRegisterUrl()} className="call-to-action-max w-button">
                  Jetzt finden lassen
                </Link>
              </div>
              <div className="hero-split">
                <img src="../../../content/images/angebot-screenshot.png" loading="lazy" alt="" className="shadow-two" />
              </div>
            </div>
          </div>
        </section>
        <section className="hero-heading-right">
          <div className="container-22">
            <div className="hero-wrapper">
              <div className="hero-split">
                <h1 className="heading-11">
                  <p>Arbeitgeber nach Deinen Wünschen</p>
                </h1>
                <p className="margin-bottom-24px">
                  Sag uns, was Dir wichtig ist und erhalte nur Angebote von Unternehmen, die zu Dir passen.
                </p>
                <Link to={getArbeitnehmerRegisterUrl()} className="call-to-action-max w-button">
                  Traumjob finden
                </Link>
              </div>
              <div className="hero-split">
                <img src="../../../content/images/eigenschaften-screenshot.png" loading="lazy" alt="" className="shadow-two" />
              </div>
            </div>
          </div>
        </section>
        <section className="hero-heading-left">
          <div className="container-22">
            <div className="hero-wrapper">
              <div className="hero-split">
                <h1 className="heading-11">
                  <p>Mit einem Klick zum Bewerbungsgespräch</p>
                </h1>
                <p className="margin-bottom-24px">Super Angebot erhalten? Mit nur einem Klick hast Du ein Bewerbungsgespräch.</p>
                <Link to={getArbeitnehmerRegisterUrl()} className="call-to-action-max w-button">
                  Profil erstellen
                </Link>
              </div>
              <div className="hero-split">
                <img src="../../../content/images/zusage-screenshot.png" loading="lazy" alt="" className="shadow-two" />
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="padding-section-30"></div>
      <section className="section-image6-green">
        <div className="padding-5"></div>
        <div className="container-std">
          <h2 className="heading-2 white">Was unsere Kunden über uns sagen</h2>
          <Testimonial />
        </div>
        <div className="padding-5"></div>
      </section>

      <div>
        <div className="padding-section-30"></div>
        <div className="container-std w-container">
          <h1 className="heading-1">Und so funktioniert's</h1>
          <p className="heading-float-2-9">
            Mit Flendly erhältst Du künftig Angebote von Unternehmen, ohne etwas dafür zu tun. Und das völlig{' '}
            <span className="text-span-4">kostenlos</span>.
          </p>
          <div className="w-layout-grid grid-std">
            <div id="w-node-cecc23de-bd98-7beb-7135-630601647223-2141cc0b" className="div-block-grid-std backround-ci">
              <h1 className="heading-1 white">1</h1>
              <h2 id="w-node-cecc23de-bd98-7beb-7135-630601647226-2141cc0b" className="heading-4 white">
                Kostenlos
                <br />
                registrieren
              </h2>
              <p className="paragraph-std white">
                In wenigen Minuten Dein persönliches Profil anlegen. Völlig kostenlos und unverbindlich.
              </p>
            </div>
            <div id="w-node-cecc23de-bd98-7beb-7135-63060164722c-2141cc0b" className="div-block-grid-std backround-ci">
              <h1 className="heading-1 white">2</h1>
              <h2 id="w-node-cecc23de-bd98-7beb-7135-63060164722f-2141cc0b" className="heading-4 white">
                Unternehmen
                <br />
                Ausschließen
              </h2>
              <p className="paragraph-std white">
                Alle Unternehmen ausschließen, von denen Du nicht gefunden werden willst, wie z.B. Dein aktueller Arbeitgeber.{' '}
              </p>
            </div>
            <div id="w-node-cecc23de-bd98-7beb-7135-630601647235-2141cc0b" className="div-block-grid-std backround-ci">
              <h1 className="heading-1 white">3</h1>
              <h2 id="w-node-cecc23de-bd98-7beb-7135-630601647238-2141cc0b" className="heading-4 white">
                Angebot <br />
                erhalten
              </h2>
              <p className="paragraph-std white">
                Du erhältst Angebote von Unternehmen die Deinen Anforderungen (Gehalt, Ort…) entsprechen.{' '}
              </p>
            </div>
            <div id="w-node-cecc23de-bd98-7beb-7135-63060164723e-2141cc0b" className="div-block-grid-std backround-ci">
              <h1 className="heading-1 white">4</h1>
              <h2 id="w-node-cecc23de-bd98-7beb-7135-630601647241-2141cc0b" className="heading-4 white">
                Angebote
                <br />
                Anfragen<strong> </strong>
              </h2>
              <p className="paragraph-std white">
                Falls Du ein Unternehmen findest, das Dich besonders interessiert, frag mit einem einzigen Klick ein Angebot an.
              </p>
            </div>
          </div>
          <div className="padding-30"></div>
        </div>
      </div>
    </body>
  );
};

export default Arbeitnehmer;
