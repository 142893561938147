import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IRecommendation } from 'app/shared/model/recommendation.model';
import { getRecommendationsById } from 'app/modules/suche/recommendation/recommendation.reducer';
import { rejectAnfrage } from 'app/shared/reducers/anfrage.reducer';
import { IAnfrage } from 'app/shared/model/anfrage.model';
import { INotification } from 'app/shared/model/notification.model';
import { markNotificationAsDone } from 'app/shared/reducers/notification.reducer';
import { IArbeitgeber } from 'app/shared/model/arbeitgeber.model';
import { getArbeitgeber } from 'app/shared/reducers/arbeitgeber.reducer';

export const SucheArbeitgeberAnfrageReject = () => {
  const dispatch = useAppDispatch();

  let navigate = useNavigate();
  const { id } = useParams<'id'>();

  const notification: INotification = useAppSelector(state => state.notifications.entity);
  const isProcessing: boolean = useAppSelector(state => state.notifications.isProcessing);
  const recommendation: IRecommendation = useAppSelector(state => state.recommendation.entity);
  const arbeitgeber: IArbeitgeber = useAppSelector(state => state.arbeitgeber.entity);

  const [message, setMessage] = useState<string>();

  useEffect(() => {
    dispatch(getRecommendationsById(id));
  }, []);

  useEffect(() => {
    if (recommendation.arbeitgeberId) {
      dispatch(getArbeitgeber(recommendation.arbeitgeberId));
    }
  }, [recommendation]);

  function handleSendReasons() {
    if (recommendation) {
      let anfrage: IAnfrage = {
        arbeitgeberId: recommendation.arbeitgeberId,
        arbeitnehmerId: recommendation.arbeitnehmerId,
        arbeitnehmerSucheId: recommendation.arbeitnehmerSucheId,
        recommendationId: recommendation.id,
        message: message,
      };
      dispatch(rejectAnfrage(anfrage))
        .then(() => dispatch(markNotificationAsDone(notification.id)))
        .then(() => navigate('/suche/arbeitgeber/profiles'));
    }
  }

  function handleAbort() {
    dispatch(markNotificationAsDone(notification.id));
    navigate('/suche/arbeitgeber/' + recommendation.arbeitnehmerSucheId);
  }

  function loaded() {
    return recommendation.id;
  }

  return (
    <div>
      {loaded() && (
        <div className="notification-pop-ups-feedback">
          <h1 className="heading-3">Angebot Ablehnen </h1>
          <div className="paragraph-std">
            Bitte geben Sie eine kurze Begründung, warum Sie das Angebot der Firma {arbeitgeber.unternehmensname} ablehnen.
          </div>
          <div className="padding-10"></div>
          <div className="field-label-std">Begründung</div>
          <div className="form-block-left w-form">
            <form
              id="wf-form-"
              name="wf-form-"
              data-name=""
              method="get"
              data-wf-page-id="6511e277656f1bddbeaaf7e9"
              data-wf-element-id="605327a5-ae7a-bb52-f102-8d8f8ed81793"
            >
              <textarea
                id="profile-description"
                name="field"
                maxLength={5000}
                value={message}
                onChange={event => setMessage(event.target.value)}
                data-name=""
                placeholder=""
                className="text-field-100-2 w-input"
              ></textarea>
            </form>
          </div>
          <div className="padding-5"></div>
          <div className="w-layout-grid grid-cancel-send-215px">
            <a id="w-node-_92b365ea-f0ef-ad46-f24b-c294c331fcc6-beaaf7e9" onClick={handleAbort} className="call-to-action-max w-button">
              Abbrechen
            </a>
            <a
              id="w-node-_1828e4e6-d6c2-56fa-6f1b-73f990f7f9c9-beaaf7e9"
              onClick={handleSendReasons}
              className="call-to-action-max w-button"
            >
              Senden
            </a>
          </div>
          <div className="padding-10"></div>
        </div>
      )}
    </div>
  );
};

export default SucheArbeitgeberAnfrageReject;
