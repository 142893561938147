import { defaultSuchkriterien, ISuchkriterien } from 'app/shared/model/suchkriterien.model';
import { defaultArbeitnehmer, IArbeitnehmer } from 'app/shared/model/arbeitnehmer.model';

export interface IInterviewArbeitnehmer {
  arbeitnehmer: IArbeitnehmer | null;
  suchkriterien: ISuchkriterien | null;
}

export const defaultInterview: Readonly<IInterviewArbeitnehmer> = {
  arbeitnehmer: defaultArbeitnehmer,
  suchkriterien: defaultSuchkriterien,
};
