import React, { useEffect, useRef, useState } from 'react';
import { INotification } from 'app/shared/model/notification.model';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { toast } from 'react-toastify';
import { IArbeitgeber } from 'app/shared/model/arbeitgeber.model';
import { IArbeitnehmersuche } from 'app/shared/model/arbeitnehmersuche.model';
import { acceptAngebot, getAngebot, rejectAngebot } from 'app/shared/reducers/angebot.reducer';
import { getArbeitgeber as getArbeitgeber } from 'app/shared/reducers/arbeitgeber.reducer';
import { getArbeitnehmerSuche as getarbeitnehmersuche } from 'app/shared/reducers/arbeitnehmersuche.reducer';
import { IAngebot } from 'app/shared/model/angebot.model';
import { MitarbeiterAnzahl, Quereinsteiger } from 'app/shared/model/enumerations/mitarbeiterAnzahl.model';
import { useNavigate } from 'react-router-dom';
import { markNotificationAsDone } from 'app/shared/reducers/notification.reducer';
import Accordion from '@mui/material/Accordion';
import { AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import { FlendlyAccordion, FlendlyAccordionSummary } from 'app/modules/about/faq';
import { getEigenschaftByKey } from 'app/shared/util/entity-utils';
import { Domains } from 'app/shared/model/enumerations/domains.model';

interface NotificationProps {
  notification: INotification;
  closeModal?: () => void;
}

export const Angebot: React.FC<NotificationProps> = ({ notification, closeModal }) => {
  const dispatch = useAppDispatch();

  const angebot: IAngebot = useAppSelector(state => state.angebot.entity);
  let navigate = useNavigate();

  const arbeitgeber: IArbeitgeber = useAppSelector(state => state.arbeitgeber.entity);
  const arbeitnehmersuche: IArbeitnehmersuche = useAppSelector(state => state.arbeitnehmersuche.entity);

  useEffect(() => {
    if (notification) {
      console.log(notification);

      let angebotId = notification.keyValueMap['angebotId'];
      if (angebotId && angebotId !== 'undefined') {
        dispatch(getAngebot(angebotId));
      } else {
        console.log('angebotId is not defined');
        toast.error('angebotId is not defined');
      }
    }
  }, [notification]);

  useEffect(() => {
    if (angebot.arbeitgeberId) {
      dispatch(getArbeitgeber(angebot.arbeitgeberId));
      dispatch(getarbeitnehmersuche(angebot.arbeitnehmerSucheId));
    }
  }, [angebot]);

  function loaded() {
    return arbeitgeber.userID && arbeitnehmersuche.userID;
  }

  function handleNotInterested() {
    closeModal();
    navigate('suche/arbeitnehmer/angebot/reject/' + angebot.id);
  }

  function handleAccepted() {
    dispatch(acceptAngebot(angebot))
      .then(() => closeModal())
      .then(() => dispatch(markNotificationAsDone(notification.id)));
  }

  const [open, setOpen] = useState('0');
  const toggle = id => {
    if (open === id) {
      setOpen(id);
    } else {
      setOpen(id);
    }
  };

  return (
    <div>
      {loaded() && (
        <section>
          <div className="container-std w-container">
            <div className="wrapper-735px">
              <div className="padding-30-speziale"></div>
              <div className="card-details-copy">
                <div className="card-heading">
                  Angebot Für eine Anstellung als <strong>{arbeitnehmersuche.auswahlBeruf}</strong>
                </div>
                <div className="padding-10"></div>
                <p className="paragraph-10">
                  Die Firma <strong>{arbeitgeber.unternehmensname}</strong> unterbreitet Ihnen ein unverbindliches Angebot und lädt Sie zum
                  Bewerbungsgespräch ein.
                </p>
                <div className="card-text-blue">Angebotenes Monatsgehalt (netto)</div>
                <div className="card-field-min-215px">
                  <strong>{angebot.gehalt}€</strong>
                </div>
                <div className="card-text-blue">Darum sollten Sie bei uns arbeiten</div>
                <div className="paragraph-left-white-100">{angebot.message}</div>
                <div className="padding-20"></div>
                <div id="8-single-select" className="w-layout-grid grid-cancel-send-215px">
                  <a
                    id="w-node-d251d17c-a1b5-cbd2-54bd-d7deacbd02d8-1e498185"
                    onClick={handleNotInterested}
                    className="call-to-action-red-max-100 w-button"
                  >
                    ablehnen
                  </a>
                  <a
                    id="w-node-_2b8484b5-8342-8316-37b0-82287e0490f0-1e498185"
                    className="call-to-action-max-45 w-button"
                    onClick={handleAccepted}
                  >
                    annehmen
                  </a>
                </div>
              </div>
              <FlendlyAccordion>
                <FlendlyAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                  <h1 className="card-heading">Unternehmensprofil</h1>
                </FlendlyAccordionSummary>
                <AccordionDetails>
                  <div
                    data-hover="false"
                    data-delay="0"
                    data-w-id="a3d2e75b-2523-9ca0-9afa-073ff127dd2b"
                    className="accordion-std w-dropdown"
                  >
                    <nav className="dropdown-accordion w-dropdown-list">
                      <div className="card-transparent">
                        {/*                        <img
                          className="image-card-anfrage-ag"
                          src="../images/Maier-GbR.png"
                          width="181"
                          height="65"
                          alt=""
                          sizes="(max-width: 479px) 35vw, 170px"
                          id="w-node-_322c09e5-fe59-6b54-1691-ed34ec8b095b-1e498185"
                          loading="lazy"
                          srcSet="../images/Maier-GbR-p-500.png 500w, ../images/Maier-GbR.png 645w"
                        />*/}

                        <div className="company-card-text-first">Firma</div>
                        <div className="card-field-min-215px">{arbeitgeber.unternehmensname}</div>
                        <div className="div-block-21">
                          <div>
                            <div className="card-text-blue">Anzahl Mitarbeiter</div>
                            <div className="card-field-min-215px">
                              {MitarbeiterAnzahl[arbeitgeber.auswahlMitarbeiterAnzahl[0]]} Mitarbeiter
                            </div>
                          </div>
                          <div className="horizontal-padding-3"></div>
                          <div>
                            <div className="card-text-blue">Quereinstieg möglich</div>
                            <div className="_215px">
                              <div className="text-grid-white-backr">
                                {arbeitgeber.auswahlQuereinsteiger.map(value => Quereinsteiger[value])}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-text-blue">Das zeichnet uns besonders aus</div>
                        <div className="w-layout-grid grid">
                          {arbeitgeber.auswahlArbeitgeberEigenschaften.map((branche, index) => (
                            <div key={index} id="w-node-_322c09e5-fe59-6b54-1691-ed34ec8b0975-1e498185" className="text-grid-white-backr">
                              {getEigenschaftByKey(branche)}
                            </div>
                          ))}
                        </div>

                        <div className="card-text-blue">Das sollten Sie über uns wissen</div>
                        <p className="paragraph-left-655px">{arbeitgeber.beschreibung}</p>
                        <div className="card-text-blue">Wir suchen</div>
                        <div className="w-layout-grid grid-215px">
                          <div id="w-node-_322c09e5-fe59-6b54-1691-ed34ec8b0986-1e498185" className="text-grid-white-backr">
                            {arbeitnehmersuche.auswahlBeruf}
                          </div>
                        </div>
                        <div className="card-text-blue">Hier sind wir tätig</div>
                        <div className="w-layout-grid grid-branche">
                          {arbeitgeber.auswahlBranchen.map((branche, index) => (
                            <div className="text-grid-white-backr">{Domains[branche]}</div>
                          ))}
                        </div>
                        <div className="card-text-blue">Adresse</div>
                        <div className="w-layout-grid grid-adress">
                          <div id="w-node-_322c09e5-fe59-6b54-1691-ed34ec8b099a-1e498185" className="text-grid-white-backr left">
                            {arbeitgeber.adresse}
                          </div>
                          <div id="w-node-_322c09e5-fe59-6b54-1691-ed34ec8b099c-1e498185" className="text-grid-white-backr left">
                            {arbeitgeber.plz} {arbeitgeber.ort}
                          </div>
                        </div>
                        <div className="card-text-blue">Unternehmenswebsite</div>
                        <div className="card-field-min-215px">{arbeitgeber.webseite}</div>
                      </div>
                    </nav>
                  </div>
                </AccordionDetails>
              </FlendlyAccordion>

              <div className="padding-20"></div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};
