export interface IArbeitgeber {
  id?: string;
  beschreibung?: string | null;
  logo?: string | null;
  unternehmensname?: string;
  telefon?: string;
  adresse?: string;
  zusatz?: string;
  plz?: string;
  ort?: string;
  ansprechpartner?: string;
  email?: string;
  webseite?: string;
  userID: string;
  auswahlArbeitgeberEigenschaften?: string[] | null;
  auswahlBranchen?: [] | null;
  auswahlMitarbeiterAnzahl?: string[] | null;
  auswahlQuereinsteiger?: string[] | null;
  auswahlArbeitnehmerEigenschaften?: string[] | null;
}

export const defaultArbeitgeber: Readonly<IArbeitgeber> = {
  beschreibung: '',
  logo: '',
  unternehmensname: '',
  adresse: '',
  zusatz: '',
  plz: '',
  ort: '',
  ansprechpartner: '',
  email: '',
  webseite: '',
  userID: null,
  auswahlArbeitgeberEigenschaften: [],
  auswahlBranchen: [],
  auswahlMitarbeiterAnzahl: [],
  auswahlQuereinsteiger: [],
  auswahlArbeitnehmerEigenschaften: [],
};
