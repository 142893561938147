import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useNavigate, useParams } from 'react-router-dom';
import { IRecommendation, IRecommendationStatus } from 'app/shared/model/recommendation.model';
import { IArbeitnehmersuche } from 'app/shared/model/arbeitnehmersuche.model';
import { IArbeitgeber } from 'app/shared/model/arbeitgeber.model';
import { IArbeitnehmer } from 'app/shared/model/arbeitnehmer.model';
import { ISuchkriterien } from 'app/shared/model/suchkriterien.model';
import { getArbeitgeber } from 'app/shared/reducers/arbeitgeber.reducer';
import { getArbeitnehmerSuche as getArbeitnehmersuche } from 'app/shared/reducers/arbeitnehmersuche.reducer';
import { toast } from 'react-toastify';
import { getRecommendationsForArbeitnehmer, updateRecommendation } from 'app/modules/suche/recommendation/recommendation.reducer';
import { MitarbeiterAnzahl, Quereinsteiger } from 'app/shared/model/enumerations/mitarbeiterAnzahl.model';
import { getArbeitnehmer } from 'app/shared/reducers/arbeitnehmer.reducer';
import { getEigenschaftByKey } from 'app/shared/util/entity-utils';
import { Domains } from 'app/shared/model/enumerations/domains.model';

const SucheArbeitnehmerDetails = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  const account = useAppSelector(state => state.authentication.account);

  const recommendationList: IRecommendation[] = useAppSelector(state => state.recommendation.entities);
  const arbeitnehmerSuche: IArbeitnehmersuche = useAppSelector(state => state.arbeitnehmersuche.entity);
  const arbeitgeber: IArbeitgeber = useAppSelector(state => state.arbeitgeber.entity);
  const arbeitnehmer: IArbeitnehmer = useAppSelector(state => state.arbeitnehmer.entity);
  const suchkriterien: ISuchkriterien = useAppSelector(state => state.suchkriterien.entity);

  const [recommendation, setRecommendation] = React.useState<IRecommendation>();
  const navigate = useNavigate();

  useEffect(() => {
    if (recommendationList.length > 0) {
      const iRecommendation = recommendationList.find(r => r.id === id);

      if (iRecommendation) {
        setRecommendation(iRecommendation);
        dispatch(getArbeitnehmer(account.id));
        dispatch(getArbeitgeber(iRecommendation.arbeitgeberId));
        dispatch(getArbeitnehmersuche(iRecommendation.arbeitnehmerSucheId));
      } else {
        toast.error('Error loading recommendation');
      }
    } else if (recommendationList.length === 0) {
      toast.error('Error loading recommendation list');
    }
  }, []);

  function handleNotInteresting() {
    let updatedRecommendation = { ...recommendation, status: IRecommendationStatus.REJECTED };
    updateRecommendationStatus(updatedRecommendation);
  }

  function handleInterested() {
    let updatedRecommendation = { ...recommendation, status: IRecommendationStatus.ACCEPTED };
    updateRecommendationStatus(updatedRecommendation);
  }

  function updateRecommendationStatus(recommendation: IRecommendation) {
    dispatch(updateRecommendation(recommendation))
      .then(() => {
        toast.success('Unternehmen wurde abgelehnt');
        dispatch(getRecommendationsForArbeitnehmer({ userID: arbeitnehmerSuche.id, includeDeclined: false }));
      })
      .then(() => {
        navigate('/suche/arbeitnehmer/');
      })
      .catch(reason => toast.error('Error updating recommendation: \n' + reason));
  }

  function loaded() {
    return recommendation && recommendation.id && arbeitgeber.userID && arbeitnehmer.userID && suchkriterien.userID;
  }

  return (
    <div>
      {loaded() && (
        <section>
          <div className="container-std w-container">
            <div className="padding-20" />
            <div className="wrapper-735px-details">
              <div className="card-details">
                <img
                  src="../images/Maier-GbR.png"
                  loading="lazy"
                  sizes="(max-width: 479px) 100px, 170px"
                  height="65"
                  alt=""
                  srcSet="../images/Maier-GbR-p-500.png 500w, ../images/Maier-GbR.png 645w"
                  className="image-card-ag-details"
                />

                {/* Unternehmensname */}
                <h1 className="card-heading">{arbeitgeber.unternehmensname}</h1>
                <div className="padding-10"></div>

                {/* Anzahl Mitarbeiter */}
                <div className="div-block-21">
                  <div>
                    <div className="card-text-blue">Anzahl Mitarbeiter</div>
                    <div className="card-field-min-215px">{MitarbeiterAnzahl[arbeitgeber.auswahlMitarbeiterAnzahl[0]]} Mitarbeiter</div>
                  </div>
                  <div className="horizontal-padding-3"></div>

                  <div>
                    <div className="card-text-blue">Quereinstieg möglich</div>
                    <div className="_215px">
                      <div className="card-field-min-215px">{arbeitgeber.auswahlQuereinsteiger.map(value => Quereinsteiger[value])}</div>
                    </div>
                  </div>
                </div>

                {/* Benefits */}
                <div className="card-text-blue">Das zeichnet uns besonders aus</div>
                <div className="w-layout-grid">
                  {arbeitgeber.auswahlArbeitgeberEigenschaften.map((benefit, index) => (
                    <div key={index} id="w-node-_93e7d1d0-7897-a5f5-4b77-9450cbef56ca-1e4981ba" className="text-grid-white-backr">
                      {getEigenschaftByKey(benefit)}
                    </div>
                  ))}
                </div>

                {/* Beschreibung */}
                <div className="card-text-blue">Das sollten Sie über uns wissen</div>
                <p className="paragraph-left-655px">{arbeitgeber.beschreibung}</p>

                {/* Wir suchen */}
                <div className="card-text-blue">Wir suchen</div>
                <div className="w-layout-grid">
                  <div id="w-node-_93e7d1d0-7897-a5f5-4b77-9450cbef56db-1e4981ba" className="text-grid-white-backr">
                    {arbeitnehmerSuche.auswahlBeruf}
                  </div>
                  <div id="w-node-_93e7d1d0-7897-a5f5-4b77-9450cbef56db-1e4981ba" className="text-grid-white-backr">
                    {arbeitnehmerSuche.auswahlAusbildungsstand}
                  </div>
                  <div id="w-node-_93e7d1d0-7897-a5f5-4b77-9450cbef56db-1e4981ba" className="text-grid-white-backr">
                    {arbeitnehmerSuche.auswahlBerufserfahrung}
                  </div>
                </div>

                {/* Wir bieten */}
                <div className="card-text-blue">Hier sind wir tätig</div>
                <div className="w-layout-grid grid-branche">
                  <div id="w-node-_93e7d1d0-7897-a5f5-4b77-9450cbef56e0-1e4981ba" className="text-grid-white-backr">
                    Handwerk
                  </div>
                  {arbeitgeber.auswahlBranchen.map((branche, index) => (
                    <div className="text-grid-white-backr">{Domains[branche]}</div>
                  ))}
                </div>

                {/* Kontakt */}
                <div className="card-text-blue">Adresse</div>
                <div className="w-layout-grid grid-adress">
                  <div id="w-node-_93e7d1d0-7897-a5f5-4b77-9450cbef56f1-1e4981ba" className="text-grid-white-backr">
                    {arbeitgeber.adresse}
                  </div>
                  <div id="w-node-_93e7d1d0-7897-a5f5-4b77-9450cbef56f3-1e4981ba" className="text-grid-white-backr">
                    {arbeitgeber.plz}, {arbeitgeber.ort}
                  </div>
                </div>
                <div className="card-text-blue">Unternehmenswebsite</div>
                <div className="text-grid-white-backr">
                  <div className="text-grid-white-backr">{arbeitgeber.webseite}</div>
                </div>
                <div className="padding-10"></div>

                <div id="104-single-select45" className="w-layout-grid company-card-buttons">
                  <div
                    id="w-node-_93e7d1d0-7897-a5f5-4b77-9450cbef56fc-1e4981ba"
                    className="call-to-action-red-max-100"
                    onClick={() => handleNotInteresting()}
                  >
                    Nicht interessant
                  </div>
                  <div
                    id="w-node-_93e7d1d0-7897-a5f5-4b77-9450cbef56fe-1e4981ba"
                    className="call-to-action-max-100"
                    onClick={() => handleInterested()}
                  >
                    Anfrage senden
                  </div>
                </div>
              </div>
            </div>
            <div className="padding-20"></div>
          </div>
        </section>
      )}
    </div>
  );
};

export default SucheArbeitnehmerDetails;
