import './footer.scss';

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

const Footer = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = event => {
    event.preventDefault();

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!emailRegex.test(email)) {
      toast.error('Bitte geben Sie eine gültige E-Mail-Adresse ein.');
      return;
    }

    axios.post('/api/newsletter', { email });
    setEmail('');
    toast.success('Vielen Dank für Ihre Newsletter Registrierung!');
  };

  return (
    <section className="footer-light">
      <div className="container-11">
        <section className="footer-light">
          <div className="footer-wrapper-two main-content">
            <div className="footer-block-two">
              <div className="footer-title">Unternehmen</div>
              <a className="footer-link-two" href="/flendly">
                Über Flendly
              </a>
              <a className="footer-link-two" href="/faq">
                FAQ
              </a>
              <a className="footer-link-two" href="/kontakt">
                Kontakt
              </a>
            </div>

            <div className="footer-block-two">
              <div className="footer-title">Rechtliches</div>
              <a className="footer-link-two" href="/impressum">
                Impressum
              </a>
              <a className="footer-link-two" href="/agb">
                AGB
              </a>
              <a className="footer-link-two" href="/datenschutz">
                Datenschutzerklärung
              </a>
            </div>

            <div className="footer-form w-form">
              <form onSubmit={handleSubmit} className="footer-form-container">
                <div className="footer-title">Newsletter anmelden</div>
                <div className="footer-form-block">
                  <input
                    type="email"
                    className="footer-form-field w-input"
                    name="email"
                    data-name="email"
                    aria-label="Get product updates"
                    placeholder="Email Adresse"
                    id="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                  />
                  <input type="submit" data-wait="Please wait..." className="footer-form-submit w-button" value="" />
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};
export default Footer;
