import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IArbeitgeber, defaultArbeitgeber } from 'app/shared/model/arbeitgeber.model';
import { getAccount } from 'app/shared/reducers/authentication';

const initialState: EntityState<IArbeitgeber> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultArbeitgeber,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/arbeitgebers';

// Actions

export const getEntities = createAsyncThunk('arbeitgeber/fetch_entity_list', async ({}: IQueryParams) => {
  const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
  return axios.get<IArbeitgeber[]>(requestUrl);
});

export const getArbeitgeberByIds = createAsyncThunk(
  'arbeitgeber/fetch_entity',
  async (ids: string[]) => {
    const requestUrl = `${apiUrl}/ids`;
    return axios.post<IArbeitgeber[]>(requestUrl, ids);
  },
  { serializeError: serializeAxiosError }
);

export const getArbeitgeber = createAsyncThunk(
  'arbeitgeber/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IArbeitgeber>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createFromInterview = createAsyncThunk(
  'arbeitgeber/interview',
  async (entity: any, thunkAPI) => {
    const formData = new FormData();
    Object.keys(entity).forEach(key => {
      // Wenn das Schlüsselelement eine Datei ist, fügen Sie die Datei dem FormData hinzu
      if (entity[key] instanceof File) {
        formData.append(key, entity[key]);
      }
    });
    const arbeitgeberBlob = new Blob([JSON.stringify(entity.arbeitgeber)], { type: 'application/json' });

    formData.append('arbeitgeber', arbeitgeberBlob);

    // Definieren Sie die Konfiguration für den axios-Aufruf
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    const result = await axios.post<IArbeitgeber>(apiUrl + '/interview', formData, config); // Use formData here
    thunkAPI.dispatch(getEntities({}));
    thunkAPI.dispatch(getAccount());
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'arbeitgeber/create_entity',
  async (entity: IArbeitgeber, thunkAPI) => {
    const result = await axios.post<IArbeitgeber>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    thunkAPI.dispatch(getAccount());
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'arbeitgeber/update_entity',
  async (entity: IArbeitgeber, thunkAPI) => {
    const result = await axios.put<IArbeitgeber>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'arbeitgeber/partial_update_entity',
  async (entity: IArbeitgeber, thunkAPI) => {
    const result = await axios.patch<IArbeitgeber>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'arbeitgeber/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IArbeitgeber>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const ArbeitgeberSlice = createEntitySlice({
  name: 'arbeitgeber',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getArbeitgeber.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = defaultArbeitgeber;
      })
      .addMatcher(isFulfilled(getEntities, getArbeitgeberByIds), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getArbeitgeber), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = ArbeitgeberSlice.actions;

// Reducer
export default ArbeitgeberSlice.reducer;
