import { Arbeitsverhaeltnis } from 'app/shared/model/enumerations/arbeitsverhaeltnis.model';
import { ISprache } from 'app/shared/model/sprache.model';
import { IBeruf } from 'app/shared/model/beruf.model';
import { License } from 'app/shared/model/enumerations/license.model';
import { IArbeitsort } from 'app/shared/model/arbeitsort.model';
import { IZeitpunkt } from 'app/shared/model/zeitpunkt.model';

export interface ISuchkriterien {
  id?: string;
  userID: string;
  auswahlMontage: string[];
  auswahlArbeitsverhaeltnis?: Arbeitsverhaeltnis[];
  auswahlEigenschaften: string[];
  auswahlBeruf: IBeruf;
  auswahlSprache: ISprache[] | null;
  auswahlLicense: License[] | null;
  arbeitsort: IArbeitsort | null;
  auswahlGehalt: string;
  auswahlZeitpunkt?: IZeitpunkt;
}

export const defaultSuchkriterien: Readonly<ISuchkriterien> = {
  userID: null,
  auswahlMontage: [],
  auswahlEigenschaften: [],
  auswahlBeruf: null,
  auswahlSprache: [],
  auswahlLicense: [],
  arbeitsort: null,
  auswahlGehalt: '',
};
