import React, { useEffect, useState } from 'react';
import { Berufserfahrung } from 'app/shared/model/enumerations/Berufserfahrung';
import { Ausbildungsstand } from 'app/shared/model/enumerations/ausbildungsstand.model';
import { GenericAuswahl } from 'app/shared/components/generic-auswahl';
import FlendlyValidateField from 'app/modules/assets/FlendlyValidateField';
import { defaultValue, IBeruf } from 'app/shared/model/beruf.model';
import { Berufe } from 'app/shared/model/enumerations/berufe.model';
import { useAppSelector } from 'app/config/store';

interface MultiSelectProps {
  onAuswahlChange: (value: any) => void;
  initialValue: IBeruf;
  formEnabled?: boolean;
}

export const BerufAuswahlMultiSelect: React.FC<MultiSelectProps> = ({ onAuswahlChange, initialValue }) => {
  const [beruf, setBeruf] = useState<IBeruf>(defaultValue);

  const account = useAppSelector(state => state.authentication.account);

  useEffect(() => {
    if (initialValue) {
      setBeruf(initialValue);
    } else {
      setBeruf(prev => {
        return { ...prev, beruf: { ...prev, ['userID']: account.id } };
      });
    }
  }, []);

  function setBerufValue(value: any, field: string) {
    let currentberuf = beruf;
    currentberuf[field] = value;
    setBeruf(currentberuf);

    onAuswahlChange(currentberuf);
  }

  return (
    <div>
      <div>
        <GenericAuswahl
          label="Beruf"
          minSelect={1}
          maxSelect={1}
          initialValue={[beruf.auswahlBeruf]}
          onAuswahlChange={value => setBerufValue(value[0], 'auswahlBeruf')}
          col={Berufe}
          title={'Bitte geben Sie Ihren Beruf an'}
        />

        <div className="padding-10"></div>

        <p className="paragraph-workflow">
          <strong>Bitte gebe Deinen Fachbereich an.</strong>
          <div className="padding-10"></div>
        </p>

        <FlendlyValidateField
          placeholder="Bitte geben Sie Ihren Fachbereich ein"
          label="Fachbereich"
          inputClass="text-field-45px w-input"
          type="text"
          name="berufe"
          id="beruf-auswahl"
          onChange={value => setBerufValue(value.target.value, 'auswahlFachbereich')}
          value={beruf.auswahlFachbereich}
          wrapperClass="paragraph-workflow paragraph-display-start"
        ></FlendlyValidateField>

        <div className="padding-10"></div>

        <GenericAuswahl
          label="Ausbildungsstand"
          minSelect={1}
          maxSelect={1}
          initialValue={[beruf.auswahlAusbildungsstand]}
          onAuswahlChange={value => setBerufValue(value[0], 'auswahlAusbildungsstand')}
          col={Ausbildungsstand}
          title={'Bitte geben Sie Ihren Ausbildungsstand an'}
        />

        <GenericAuswahl
          label="Berufserfahrung"
          minSelect={1}
          maxSelect={1}
          initialValue={[beruf.auswahlBerufserfahrung]}
          onAuswahlChange={value => setBerufValue(value[0], 'auswahlBerufserfahrung')}
          col={Berufserfahrung}
          title={'Bitte geben Sie Ihre Berufserfahrung an'}
        />
      </div>
    </div>
  );
};
