import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { createEntitySlice, EntityState, IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultSuchkriterien, ISuchkriterien } from 'app/shared/model/suchkriterien.model';

const initialState: EntityState<ISuchkriterien> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultSuchkriterien,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/suchkriteriens';

// Actions

export const getEntities = createAsyncThunk('suchkriterien/fetch_entity_list', async ({}: IQueryParams) => {
  const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
  return axios.get<ISuchkriterien[]>(requestUrl);
});

export const getSuchkriterienByIds = createAsyncThunk(
  'suchkriterien/fetch_entity',
  async (id: string[]) => {
    /*make the ids as request body */
    const requestUrl = `${apiUrl}/ids`;
    return axios.post<ISuchkriterien[]>(requestUrl, id);
  },
  { serializeError: serializeAxiosError }
);

export const getSuchkriterienByUserId = createAsyncThunk(
  'suchkriterien/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/user/${id}`;
    return axios.get<ISuchkriterien>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'suchkriterien/create_entity',
  async (entity: ISuchkriterien) => {
    return await axios.post<ISuchkriterien>(apiUrl, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'suchkriterien/update_entity',
  async (entity: ISuchkriterien, thunkAPI) => {
    const result = await axios.put<ISuchkriterien>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'suchkriterien/partial_update_entity',
  async (entity: ISuchkriterien, thunkAPI) => {
    const result = await axios.patch<ISuchkriterien>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'suchkriterien/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<ISuchkriterien>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const SuchkriterienSlice = createEntitySlice({
  name: 'suchkriterien',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getSuchkriterienByUserId.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = defaultSuchkriterien;
      })
      .addMatcher(isFulfilled(getEntities, getSuchkriterienByIds), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getSuchkriterienByUserId, getSuchkriterienByIds), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = SuchkriterienSlice.actions;

// Reducer
export default SuchkriterienSlice.reducer;
