import { Berufe } from 'app/shared/model/enumerations/berufe.model';
import { Ausbildungsstand } from 'app/shared/model/enumerations/ausbildungsstand.model';
import { Berufserfahrung } from 'app/shared/model/enumerations/Berufserfahrung';

export interface IBeruf {
  id?: string;
  userID: string;
  auswahlBeruf: string | null;
  auswahlAusbildungsstand: Ausbildungsstand | null;
  auswahlBerufserfahrung: Berufserfahrung | null;
  auswahlFachbereich?: string | null;
}

export const defaultValue: Readonly<IBeruf> = {
  auswahlAusbildungsstand: null,
  auswahlBeruf: null,
  auswahlBerufserfahrung: null,
  id: null,
  userID: null,
  auswahlFachbereich: null,
};
