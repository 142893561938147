import pick from 'lodash/pick';
import { IPaginationBaseState } from 'react-jhipster';
import { Benefits } from 'app/shared/model/enumerations/benefits.model';
import { Worklifebalance } from 'app/shared/model/enumerations/worklifebalance.model';
import { Verguetung } from 'app/shared/model/enumerations/verguetung.model';
import { Kultur } from 'app/shared/model/enumerations/kultur.model';
import { Arbeitsweise, PersönlicheEigenschaften } from 'app/shared/model/enumerations/arbeitnehmerEigenschaften';

/**
 * Removes fields with an 'id' field that equals ''.
 * This function was created to prevent entities to be sent to
 * the server with an empty id and thus resulting in a 500.
 *
 * @param entity Object to clean.
 */
export const cleanEntity = entity => {
  const keysToKeep = Object.keys(entity).filter(k => !(entity[k] instanceof Object) || (entity[k]['id'] !== '' && entity[k]['id'] !== -1));

  return pick(entity, keysToKeep);
};

/**
 * Simply map a list of element to a list a object with the element as id.
 *
 * @param idList Elements to map.
 * @returns The list of objects with mapped ids.
 */
export const mapIdList = (idList: ReadonlyArray<any>) => idList.filter((id: any) => id !== '').map((id: any) => ({ id }));

export const overridePaginationStateWithQueryParams = (paginationBaseState: IPaginationBaseState, locationSearch: string) => {
  const params = new URLSearchParams(locationSearch);
  const page = params.get('page');
  const sort = params.get('sort');
  if (page && sort) {
    const sortSplit = sort.split(',');
    paginationBaseState.activePage = +page;
    paginationBaseState.sort = sortSplit[0];
    paginationBaseState.order = sortSplit[1];
  }
  return paginationBaseState;
};

export const getEigenschaftByKey = (eigenschaft: string) => {
  if (Object.keys(Benefits).includes(eigenschaft)) {
    return Benefits[eigenschaft];
  }
  if (Object.keys(Worklifebalance).includes(eigenschaft)) {
    return Worklifebalance[eigenschaft];
  }
  if (Object.keys(Verguetung).includes(eigenschaft)) {
    return Verguetung[eigenschaft];
  }
  if (Object.keys(Kultur).includes(eigenschaft)) {
    return Kultur[eigenschaft];
  }
};

export const getArbeitnehmerSkillsByKey = (eigenschaft: string) => {
  if (Object.keys(PersönlicheEigenschaften).includes(eigenschaft)) {
    return PersönlicheEigenschaften[eigenschaft];
  }
  if (Object.keys(Arbeitsweise).includes(eigenschaft)) {
    return Arbeitsweise[eigenschaft];
  }
};
