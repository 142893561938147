import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IRecommendation } from 'app/shared/model/recommendation.model';
import { IArbeitnehmer } from 'app/shared/model/arbeitnehmer.model';
import { IArbeitnehmersuche } from 'app/shared/model/arbeitnehmersuche.model';
import { defaultValue, IAngebot } from 'app/shared/model/angebot.model';
import { createAngebot } from 'app/shared/reducers/angebot.reducer';
import { getArbeitnehmerSuche } from 'app/shared/reducers/arbeitnehmersuche.reducer';
import { getArbeitgeber } from 'app/shared/reducers/arbeitgeber.reducer';
import { getRecommendationsById, getRecommendationsForArbeitgeber } from 'app/modules/suche/recommendation/recommendation.reducer';
import { markNotificationAsDone } from 'app/shared/reducers/notification.reducer';
import { INotification } from 'app/shared/model/notification.model';
import { ISuchkriterien } from 'app/shared/model/suchkriterien.model';
import { getSuchkriterienByUserId } from 'app/shared/reducers/suchkriterien.reducer';
import { toast } from 'react-toastify';

export const SucheArbeitgeber = () => {
  const dispatch = useAppDispatch();

  const notification: INotification = useAppSelector(state => state.notifications.entity);
  const isProcessing: boolean = useAppSelector(state => state.notifications.isProcessing);

  let navigate = useNavigate();
  const { id } = useParams<'id'>();

  const recommendation: IRecommendation = useAppSelector(state => state.recommendation.entity);
  const arbeitnehmer: IArbeitnehmer = useAppSelector(state => state.arbeitnehmer.entity);
  const arbeitnehmersuche: IArbeitnehmersuche = useAppSelector(state => state.arbeitnehmersuche.entity);
  const suchkritieren: ISuchkriterien = useAppSelector(state => state.suchkriterien.entity);

  const [angebot, setAngebot] = React.useState<IAngebot>(defaultValue);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getRecommendationsById(id));
  }, []);

  useEffect(() => {
    if (recommendation.arbeitgeberId) {
      dispatch(getArbeitnehmerSuche(recommendation.arbeitnehmerSucheId));
      dispatch(getArbeitgeber(recommendation.arbeitgeberId));
      dispatch(getSuchkriterienByUserId(arbeitnehmer.userID));
    }
  }, [recommendation]);

  function handleSendAngebot() {
    if (!angebot.gehalt && angebot.gehalt.length === 0) {
      toast.error('Bitte geben Sie ein Gehalt an.');
    } else {
      const newAngebot = {
        ...defaultValue,
        arbeitnehmerId: recommendation.arbeitnehmerId,
        arbeitgeberId: recommendation?.arbeitgeberId,
        arbeitnehmerSucheId: recommendation?.arbeitnehmerSucheId,
        suchkriterienId: recommendation?.suchkriterienId,
        gehalt: angebot.gehalt,
        message: angebot.message,
        recommendationId: id,
      };

      dispatch(createAngebot(newAngebot))
        .then(() =>
          dispatch(
            getRecommendationsForArbeitgeber({
              arbeitnehmersucheID: recommendation.arbeitnehmerSucheId,
              includeDeclined: false,
            })
          )
        )
        .then(() => dispatch(markNotificationAsDone(notification.id)))
        .then(() => dispatch(getRecommendationsForArbeitgeber({ arbeitnehmersucheID: id, includeDeclined: false })))
        .then(() => navigate('/suche/arbeitgeber/' + recommendation?.arbeitnehmerSucheId));
    }
  }

  function updateAngebot(key: string, value: string) {
    setAngebot({ ...angebot, [key]: value });
  }

  function handleAbort() {
    if (isProcessing) {
      dispatch(markNotificationAsDone(notification.id));
    }
    navigate('/suche/arbeitgeber/' + arbeitnehmersuche.id);
  }

  return (
    <div>
      <section>
        <div className="container-std w-container">
          <div className="padding-10"></div>
          <div id="w-node-_0ef209d8-ec40-fad7-0e3f-9b9dac516fa0-1e498186" className="card-read-dark-grey">
            <h1 className="heading-3-copy">
              <strong>Einladung an {arbeitnehmer.auswahlAnrede} </strong> {arbeitnehmer.vorname} {arbeitnehmer.name}
            </h1>
            <div className="form-block w-form">
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                method="get"
                data-wf-page-id="650ddce7975549021e498186"
                data-wf-element-id="bd38e74e-be20-6646-0f34-d4aab0258c3e"
              >
                <label htmlFor="name-2" className="field-label-std">
                  Vorgeschlagenes Gehalt (Netto)
                </label>
                <input
                  className="text-field-fix-widtth-2 w-input"
                  maxLength={256}
                  name="name-2"
                  data-name="Name 2"
                  placeholder="z.B. 2.800 Euro"
                  type="text"
                  id="name-2"
                  required={true}
                  value={angebot.gehalt}
                  onChange={e => updateAngebot('gehalt', e.target.value)}
                />
                <p>
                  Das von {arbeitnehmer.auswahlAnrede} {arbeitnehmer.name} geforderte Mindestgehalt beträgt {suchkritieren.auswahlGehalt}€
                  pro Monat.
                </p>
                <div className="padding-10"></div>
                <label htmlFor="Begr-ndung" className="field-label-std">
                  Darum sollten Sie bei uns arbeiten (optional)
                </label>
                <textarea
                  placeholder={
                    'Beschreibung warum ' +
                    arbeitnehmer.auswahlAnrede +
                    ' ' +
                    arbeitnehmer.name +
                    ' gut' +
                    ' zu' +
                    ' Ihrem' +
                    ' Unternehmen' +
                    ' passt.'
                  }
                  maxLength={5000}
                  id="Begr-ndung"
                  name="Begr-ndung"
                  data-name="Begründung"
                  className="text-field-fix-widtth-100 w-input"
                  value={angebot.message}
                  onChange={e => updateAngebot('message', e.target.value)}
                ></textarea>
                <div className="padding-20"></div>
                <div className="padding-5"></div>
                <div className="w-layout-grid grid-cancel-send-215px">
                  <a
                    id="w-node-b7ced7ca-9f93-2235-b0a8-e0c4ecdf4a99-1e498186"
                    className="call-to-action-red-max-100 w-button"
                    onClick={handleAbort}
                  >
                    Abbrechen
                  </a>
                  <a
                    id="w-node-b7ced7ca-9f93-2235-b0a8-e0c4ecdf4a9b-1e498186"
                    className="call-to-action-max w-button"
                    onClick={handleSendAngebot}
                  >
                    Senden
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SucheArbeitgeber;
