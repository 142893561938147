import React, { FunctionComponent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { IZeitpunkt } from 'app/shared/model/zeitpunkt.model';
import { IArbeitsort } from 'app/shared/model/arbeitsort.model';

interface ArbeitsortAuswahlProps {
  onAuswahlChange: Function;
  initialValue: IArbeitsort;
  title: string;
}

export const ArbeitsortAuswahl: FunctionComponent<ArbeitsortAuswahlProps> = ({ title, onAuswahlChange, initialValue }) => {
  const [selectArbeitsort, setSelectArbeitsort] = useState('');
  const [selectUmkreis, setSelectUmkreis] = useState('');
  const [selectFlexible, setSelectFlexible] = useState(false);

  useEffect(() => {
    if (initialValue) {
      setSelectArbeitsort(initialValue.arbeitsort);
      setSelectUmkreis(initialValue.umkreis);
      setSelectFlexible(initialValue.flexibel);
    } else {
      setSelectArbeitsort('');
      setSelectUmkreis('10');
      setSelectFlexible(false);
    }
  }, [initialValue]);

  function handleOrtCheck(event) {
    const auswahlArbeitsort = event.target.value;
    setSelectArbeitsort(auswahlArbeitsort);

    if (auswahlArbeitsort.length > 5) {
      toast.error('Bitte geben Sie eine gültige Postleitzahl ein.');
      return;
    }

    const entity = {
      arbeitsort: auswahlArbeitsort,
      umkreis: selectUmkreis,
      flexibel: selectFlexible,
    };

    onAuswahlChange(entity);
  }

  function handleFlexibleCheck(event) {
    const flexible = event.target.checked;
    setSelectFlexible(flexible);
    if (flexible) {
      const entity = {
        arbeitsort: '',
        umkreis: '',
        flexibel: flexible,
      };

      onAuswahlChange(entity);
    } else {
      const entity = {
        arbeitsort: selectArbeitsort,
        umkreis: selectUmkreis,
        flexibel: flexible,
      };

      onAuswahlChange(entity);
    }
  }

  function handleUmkreisCheck(event) {
    const umkreis = event.target.value;
    setSelectUmkreis(umkreis);

    const entity = {
      arbeitsort: selectArbeitsort,
      umkreis: umkreis,
      flexibel: selectFlexible,
    };

    onAuswahlChange(entity);
  }

  return (
    <div id="employer_reg_13" className="div-block-workflow">
      <p className="paragraph-workflow">
        <strong>{title}</strong>
      </p>
      <div className="padding-10"></div>

      <div className={'wrapper-100'}>
        {selectFlexible ? (
          <div className="w-layout-grid">
            <input
              type="text"
              className="text-field-45px w-input"
              name="Beruf-Handwerk-3"
              data-name="Beruf Handwerk 3"
              placeholder="PLZ"
              id="Beruf-Handwerk-35"
              disabled
            />
            <select
              id="field-31"
              name="field-3"
              data-name="Field 3"
              className="select-field-45px w-node-d14e9ee3-8c22-478d-95c5-b120b5eaa470-1e49818d w-select"
              onChange={handleUmkreisCheck}
              value={selectUmkreis}
              disabled
              style={{
                color: selectUmkreis ? 'black' : 'gray',
              }}
            >
              <option value="10" selected>
                +10 km
              </option>
              <option value="20">+20 km</option>
              <option value="50">+50 km</option>
              <option value="100">+100 km</option>
            </select>
            {/* Weitere Elemente hier, falls nötig, auch mit `disabled` */}
          </div>
        ) : (
          <div className="w-layout-grid">
            <input
              type="text"
              pattern={'^[0-9]{5}$'}
              title={'Geben sie eine Postleitzahl ein'}
              className="text-field-45px w-input"
              name="Beruf-Handwerk-3"
              data-name="Beruf Handwerk 3"
              placeholder="PLZ"
              id="Beruf-Handwerk-35"
              onChange={handleOrtCheck}
              value={selectArbeitsort}
              required
            />
            <select
              id="field-31"
              name="field-3"
              data-name="Field 3"
              className="select-field-45px w-select"
              onChange={handleUmkreisCheck}
              value={selectUmkreis}
              required
              style={{
                color: selectUmkreis ? 'black' : 'gray',
              }}
            >
              {/*default value 10 km */}
              <option value="10" selected>
                +10 km
              </option>
              <option value="20">+20 km</option>
              <option value="50">+50 km</option>
              <option value="100">+100 km</option>
            </select>
          </div>
        )}
      </div>

      <div className="padding-5"></div>
      <label className="w-checkbox checkbox-field">
        <input
          type="checkbox"
          id="checkbox-3"
          name="checkbox-3"
          data-name="Checkbox 3"
          className="w-checkbox-input checkbox"
          onChange={handleFlexibleCheck}
          checked={selectFlexible}
        />
        <span className="checkbox-label w-form-label">Egal wo, ich bin komplett flexibel</span>
      </label>
    </div>
  );
};
