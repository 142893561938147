import React, { useState } from 'react';
import { Carousel, CarouselControl, CarouselIndicators, CarouselItem } from 'reactstrap';

const items = [
  {
    id: 1,
    content: <TestimonialCard1 />,
  },
  {
    id: 2,
    content: <TestimonialCard2 />,
  },
];

function Testimonial(props) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map(item => {
    return (
      <CarouselItem
        className="custom-tag"
        tag="div"
        key={item.id}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        {item.content}
      </CarouselItem>
    );
  });

  return (
    <div className="carousel-container">
      <Carousel activeIndex={activeIndex} next={next} previous={previous} interval={5000} ride="carousel">
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
        {slides}
        {/*<CarouselControl*/}
        {/*  direction="prev"*/}
        {/*  directionText="Previous"*/}
        {/*  onClickHandler={previous}*/}
        {/*  className="slide-control prev"*/}
        {/*/>*/}
        {/*<CarouselControl*/}
        {/*  direction="next"*/}
        {/*  directionText="Next"*/}
        {/*  onClickHandler={next}*/}
        {/*  className="slide-control next"*/}
        {/*/>*/}
      </Carousel>
    </div>
  );
}

export default Testimonial;

function TestimonialCard1() {
  return (
    <div>
      <div className="testimonial-slide-wrapper w-slide">
        <div className="testimonial-card">
          <p>
            „Ich habe mich bei Flendly angemeldet, um sicher zu sein, dass mein aktuelles Gehalt auch meinem Marktwert entspricht. Jetzt
            habe ich Sicherheit und wieder ein gutes Gefühl.“
          </p>
          <div className="testimonial-info">
            <img
              alt="Ein Profilbild des Kunden Lüttig, der in die Kamera lächelt. "
              src="../../../content/images/Mann-alt.jpg"
              loading="lazy"
              className="testimonial-image"
            />
            <div className="testimonial-author">
              <h3 className="testimonial-author">Florian Lüttig</h3>
              <div className="tagline">
                <strong className="text-testimonial beruf">Maler</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonial-slide-wrapper w-slide">
        <div className="testimonial-card">
          <p>
            „Einloggen, Suchkriterien hinterlegen und die passenden Kandidaten anschreiben. Dass es wirklich so einfach war, den passenden
            Mitarbeiter zu finden, kann ich jetzt noch nicht glauben.“
          </p>
          <div className="testimonial-info">
            <img
              alt="Ein Profilbild des Kunden Heinzl, der in die Kamera schaut. "
              loading="lazy"
              src="../../../content/images/mann-alt-2_1mann%20alt%202.jpg"
              className="testimonial-image"
            />
            <div className="testimonial-author">
              <h3 className="testimonial-author">Peter Heinzl</h3>
              <div className="tagline">
                <strong className="text-testimonial beruf">Selbstständiger Maler-Fachbetrieb</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function TestimonialCard2() {
  return (
    <div>
      <div className="testimonial-slide-wrapper w-slide">
        <div className="testimonial-card">
          <p>
            „Kurz nach meiner Anmeldung habe ich von einem Unternehmen in meiner Region ein Angebot mit annährend 30% mehr Gehalt erhalten!
            Da habe ich natürlich zugegriffen.“
          </p>
          <div className="testimonial-info">
            <img
              alt="Ein Profilbild des Kunden Steiner, der in die Kamera schaut. "
              loading="lazy"
              src="../../../content/images/Mann-jung.jpg"
              className="testimonial-image"
            />
            <div className="testimonial-author">
              <h3 className="testimonial-author">Alexander Steiner</h3>
              <div className="tagline">
                <strong className="text-testimonial beruf">Maler- und Lackierer</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonial-slide-wrapper w-slide">
        <div className="testimonial-card">
          <p>
            „Über Flendly konnte ich innerhalb kürzester Zeit erstaunlich viele interessante Kandidaten finden, den richtigen auswählen und
            von meinem Unternehmen überzeugen.“
          </p>
          <div className="testimonial-info">
            <img
              alt="Ein Profilbild des Kunden Schönthaler, der in die Kamera schaut. "
              loading="lazy"
              src="../../../content/images/mann-alt-3.jpg"
              className="testimonial-image"
            />
            <div className="testimonial-author">
              <h3 className="testimonial-author">Helmut Schönthaler</h3>
              <div className="tagline">
                <strong className="text-testimonial beruf">Industriebeschichtungen</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
