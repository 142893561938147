import React, { useEffect, useState } from 'react';
import FlendlyValidateField from 'app/modules/assets/FlendlyValidateField';

export const GehaltAuswahl = ({ onAuswahlChange, initialValue, wrapperClass }) => {
  const [selectGehalt, setSelectGehalt] = useState('');

  useEffect(() => {
    if (initialValue && initialValue.length > 0) {
      setSelectGehalt(initialValue);
      onAuswahlChange(initialValue);
    } else {
      setSelectGehalt('');
      onAuswahlChange('');
    }
  }, [initialValue]);

  function handleSelectGehalt(event) {
    const gehalt = event.target.value;
    setSelectGehalt(gehalt);
    onAuswahlChange(gehalt);
  }

  return (
    <div>
      <FlendlyValidateField
        headline="Wie viel möchten Sie mindestens verdienen?"
        label="Monatlicher Bruttolohn (in Euro)"
        id="arbeitnehmer-gehalt"
        name="gehalt"
        data-cy="gehalt"
        onChange={handleSelectGehalt}
        value={selectGehalt}
        wrapperClass={wrapperClass}
        placeholder="z.B. 2.400 Euro pro Monat"
      />
    </div>
  );
};
