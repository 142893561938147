import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IRecommendation, IRecommendationStatus } from 'app/shared/model/recommendation.model';
import { ISuchkriterien } from 'app/shared/model/suchkriterien.model';
import { IArbeitnehmersuche } from 'app/shared/model/arbeitnehmersuche.model';
import { IArbeitgeber } from 'app/shared/model/arbeitgeber.model';
import { getArbeitnehmerSuche } from 'app/shared/reducers/arbeitnehmersuche.reducer';
import { getArbeitgeber as getArbeitgeber } from 'app/shared/reducers/arbeitgeber.reducer';
import { getSuchkriterienByUserId } from 'app/shared/reducers/suchkriterien.reducer';
import { getRecommendationsForArbeitnehmer, updateRecommendation } from 'app/modules/suche/recommendation/recommendation.reducer';
import { MitarbeiterAnzahl, Quereinsteiger } from 'app/shared/model/enumerations/mitarbeiterAnzahl.model';
import { sendAnfrage } from 'app/shared/reducers/anfrage.reducer';
import { IAnfrage } from 'app/shared/model/anfrage.model';
import arbeitnehmer from 'app/modules/arbeitnehmer/arbeitnehmer';
import { Ausbildungsstand } from 'app/shared/model/enumerations/ausbildungsstand.model';
import { Berufserfahrung } from 'app/shared/model/enumerations/Berufserfahrung';
import { getEigenschaftByKey } from 'app/shared/util/entity-utils';
import { IUser } from 'app/shared/model/user.model';
import { toast } from 'react-toastify';

export const SucheArbeitnehmerAuto = () => {
  const dispatch = useAppDispatch();
  useNavigate();
  const account: IUser = useAppSelector(state => state.authentication.account);

  const suchkriterien: ISuchkriterien = useAppSelector(state => state.suchkriterien.entity);

  const recommendations: IRecommendation[] = useAppSelector(state => state.recommendation.entities);
  const arbeitgeber: IArbeitgeber = useAppSelector(state => state.arbeitgeber.entity);
  const arbeitnehmersuche: IArbeitnehmersuche = useAppSelector(state => state.arbeitnehmersuche.entity);

  const [recommendationsList, setRecommendationsList] = React.useState<IRecommendation[]>();
  const [recommendation, setRecommendation] = React.useState<IRecommendation>();

  useEffect(() => {
    if (account.id) {
      dispatch(getSuchkriterienByUserId(account.id));
      dispatch(getRecommendationsForArbeitnehmer({ userID: account.id, includeDeclined: false }));
    }
  }, [arbeitnehmer]);

  useEffect(() => {
    if (recommendations && recommendations.length > 0) {
      setRecommendationsList(recommendations);
    }
  }, [recommendations]);

  useEffect(() => {
    if (recommendationsList && recommendationsList.length > 0) {
      let iRecommendation = recommendationsList[0];
      setRecommendation(iRecommendation);
      dispatch(getArbeitgeber(iRecommendation.arbeitgeberId));
      dispatch(getArbeitnehmerSuche(iRecommendation.arbeitnehmerSucheId));
    } else {
      dispatch(getRecommendationsForArbeitnehmer({ userID: account.id, includeDeclined: false }));
    }
  }, [recommendationsList]);

  function loaded() {
    return account.id && suchkriterien.userID && arbeitgeber.userID && arbeitnehmersuche.userID && recommendation;
  }

  function handleShowDeclinedRecommendations() {
    dispatch(getRecommendationsForArbeitnehmer({ userID: account.id, includeDeclined: true }));
    /*window.location.reload();*/
  }

  function handleRecommendationDeclined() {
    const updatedRecommendation: IRecommendation = { ...recommendation, status: IRecommendationStatus.REJECTED };
    dispatch(updateRecommendation(updatedRecommendation))
      .then(() => {
        toast.info('Unternehmen wurde abgelehnt');
      })
      .then(() => {
        let iRecommendations = recommendationsList.filter(r => r.id !== recommendation.id);
        // Check if there are any recommendations left
        if (iRecommendations.length > 0) {
          setRecommendationsList(iRecommendations);
        } else {
          dispatch(getRecommendationsForArbeitnehmer({ userID: account.id, includeDeclined: false }));
          // If no recommendations left, set some state variable indicating that
          setRecommendation(null);
        }
      });
  }

  function handleRecommendationAccepted() {
    let anfrage: IAnfrage = {
      arbeitnehmerSucheId: recommendation.arbeitnehmerSucheId,
      arbeitgeberId: recommendation.arbeitgeberId,
      arbeitnehmerId: recommendation.arbeitnehmerId,
      recommendationId: recommendation.id,
    };

    dispatch(sendAnfrage(anfrage)).then(() => {
      let iRecommendations = recommendationsList.filter(r => r.id !== recommendation.id);
      // Check if there are any recommendations left
      if (iRecommendations.length > 0) {
        setRecommendationsList(iRecommendations);
      } else {
        dispatch(getRecommendationsForArbeitnehmer({ userID: account.id, includeDeclined: true }));
        // If no recommendations left, set some state variable indicating that
        setRecommendation(null);
      }
    });
  }

  return (
    <section className="container-std w-container">
      <div className="flex-content-cust card-edit-dark-grey-735px-1">
        <div>
          <h1 className="heading-3">DEIN PROFIL IST AKTIV</h1>
          <p className="paragraph-std">
            Dein Profil wird ab jetzt Arbeitgebern angezeigt, die Dir nun Angebote machen können. Sobald Du ein Angebot erhältst,
            informieren wir Dich per Email.
            <br />
          </p>
        </div>
        <div className="padding-5"></div>
        <div>
          <p className="paragraph-std">Bis dahin kannst Du dich entspannt zurücklehnen.</p>
        </div>
        <img src="../../../../content/images/suche-arbeitgeber-left-icon.png" />
        <div className="padding-10"></div>
      </div>
    </section>
  );
};

export default SucheArbeitnehmerAuto;
